// import VueCookie from 'vue-cookie'
import Cookies from 'js-cookie'
export default {
  // 获取服务端cookie
  getInServer(req) {
    const serviceCookie = {}
    if (req && req.headers && req.headers.cookie) {
      req.headers.cookie.split(';').forEach(function(val) {
        const parts = val.split('=')
        serviceCookie[parts[0].trim()] = (parts[1] || '').trim()
      })
    }
    return serviceCookie
  },
  // 获取客户端cookie
  getInClient(key) {
    return Cookies.get(key)
  },
  getOption() {
    const option = { expires: 1 }
    if (window.location.protocol === 'https:' && window.self !== window.top) {
      option.secure = 'true'
      option.sameSite = 'None'
    }
    return option
  },
  // 获取客户端cookie
  setInClient({ key, val }) {
    if (!key) {
      return
    }
    Cookies.set(key, val, this.getOption())
  },
  // 删除客户端cookie
  delInClient(key) {
    Cookies.remove(key, this.getOption())
  }
}
